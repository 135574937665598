// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investomania-account-deletion-mdx": () => import("./../../../src/pages/investomania-account-deletion.mdx" /* webpackChunkName: "component---src-pages-investomania-account-deletion-mdx" */),
  "component---src-pages-pap-account-deletion-mdx": () => import("./../../../src/pages/pap-account-deletion.mdx" /* webpackChunkName: "component---src-pages-pap-account-deletion-mdx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-termsofuse-mdx": () => import("./../../../src/pages/termsofuse.mdx" /* webpackChunkName: "component---src-pages-termsofuse-mdx" */)
}

